import React, { Component } from 'react';
import {Navigate} from "react-router-dom";

import NewsTicker from './news-ticker/NewsTicker';
import Sidebar from './sidebar/Sidebar';

import './NewsView.scss';
import {Nav, Navbar} from "react-bootstrap";
import {getSession} from "../../util/APIUtils";

import Grid from '@mui/material/Grid';

class AdminNavbar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showAdminNavbar: false
        };
    }

    setShowAdminNavbar (showBoolean) {
        this.setState({showAdminNavbar: showBoolean});
    }

    render() {
        return <div id="admin-navbar"
                    onMouseEnter={() => this.setShowAdminNavbar(true)}
                    onMouseLeave={() => this.setShowAdminNavbar(false)}>
            {(() => {
                if (this.state.showAdminNavbar) {
                    return <Navbar collapseOnSelect bg="dark" variant="dark" fixed="top" expand="lg" id="navbar">
                        <Navbar.Brand href="/admin"><div className="headerlogo"></div></Navbar.Brand>
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="ml-auto">
                                <Nav.Link href="/admin/news">News Admin</Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Navbar>
                }
            })()}
        </div>
    }
}

class NewsView extends Component{
    constructor(props){
        super(props);
        this.state = {
            token: true,
            authenticated: true,
            loading: false,
            session: [],
            hideCursor: navigator.userAgent.includes('SMART-TV') || navigator.userAgent.includes('Tizen'),
        };
    }

    UNSAFE_componentWillReceiveProps() {
        this.loadCurrentSession();
    }

    componentDidMount(){
        this.loadCurrentSession();
    }

    loadCurrentSession(){
        this.setState({
            loading:true
        });

        getSession()
        .then(response => {
            localStorage.setItem('roles', response.data.roles);
            this.setState({
                authenticated: true,
                loading: false,
                session: response.data
            });
        })
        .catch(error => {
            this.setState({
                loading: false
            });
        });
    }

    render(){
        return (
            <Grid container id="news-view" className={this.state.hideCursor ? 'cursor-none' : ''}>
                {!this.state.token ? <Navigate to='/login'/> : ''}
                {this.state.session?.roles?.includes('ROLE_N4_NEWS_ADMIN') ? <AdminNavbar /> : ''}
                <NewsTicker locationLabel={this.state.session?.locationLabel} />
                <Sidebar />
            </Grid>
        );
    }
}

export default NewsView;
