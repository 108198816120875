import React, {Component} from 'react';

class CharacterCounter extends Component {
    render() {
        return (
            <p className={'character-counter'}>{(this.props.value?.length ? this.props.value?.length : 0) + '/' + this.props.maxLength}</p>
        )
    }
}

export default CharacterCounter;