import React, {Component} from 'react';
import Moment from "react-moment/dist/index";

class DateTime extends Component{
    render() {
        let now = this.props.date,
            minute = now.getMinutes() * 6,
            hour = ((now.getHours() % 12) / 12) * 360 + 90 + minute / 12;

        const hourStyle = {transform: "rotate(" + hour + "deg)"},
            minuteStyle = {transform: "rotate(" + minute + "deg)"};

        return (
            <section id="datetime">
                <div id="analogClock">
                    <div id="hour" style={hourStyle}></div>
                    <div id="minute" style={minuteStyle}></div>
                </div>
                <div className="datetime">
                    <h2><Moment format="HH:mm" locale="de" date={this.props.date}/></h2>
                    <p><Moment format="dddd" locale="de" date={this.props.date}/></p>
                    <p><Moment format="Do MMMM" locale="de" date={this.props.date}/></p>
                </div>
            </section>
        );
    }
}

export default DateTime