import React, { Component } from 'react';

class ProjectCompletionNewsView extends Component{
    render(){
        let description = this.props.projectCompletionNews.description ? ' - ' + this.props.projectCompletionNews.description : '';
        return(
            <div id="project-completion-news-view" className="news" style={this.props.projectCompletionNews.backgroundColor ? {backgroundColor: this.props.projectCompletionNews.backgroundColor} : {}}>
                <div className="content">
                    <div className="background" style={this.props.projectCompletionNews.image ? {backgroundImage: 'url(/api/news/image/' + this.props.projectCompletionNews.image + ')'} : {}}>
                        <div className="gardient"></div>
                    </div>
                </div>
                <div className="footer">
                    <img id="team-emblem" src={'api/team/image/' + this.props.projectCompletionNews.team.logo} width="80" alt={this.props.projectCompletionNews.team.teamLabel} />
                    <h1>{this.props.projectCompletionNews.name}</h1>
                    <div className="bottom">
                        <b>Projektabschluss</b>{description}
                    </div> 
                </div>
            </div>
        );
    }
}

export default ProjectCompletionNewsView