import React, { Component } from 'react';

class ComingSoonView extends Component{
   getBackgroundClass() {
      switch (this.props.locationLabel) {
         case 'SCHEIDT':
         default:
            return 'n4news'
         case 'SAARBRUECKEN':
            return 'plansoftware'
      }
   }
    render(){
        return(
            <div id="coming-soon-view" className={"news " + this.getBackgroundClass()}>
                <div className="footer">
                    <i>coming soon...</i>
                </div>
            </div>
        );
    }
}

export default ComingSoonView