import React, { Component } from 'react';

class AppointmentNewsView extends Component{
    render(){
        let bottomSeperator = (this.props.appointmentNews.type && this.props.appointmentNews.description) ? ' - ' : '';

        return(
            <div id="appointment-news-view" className="news" style={this.props.appointmentNews.backgroundColor ? {backgroundColor: this.props.appointmentNews.backgroundColor} : {}}>
                <div className="content">
                    <div className="background" style={this.props.appointmentNews.image ? {backgroundImage: 'url(/api/news/image/' + this.props.appointmentNews.image + ')'} : {}}>
                        <div className="gardient"></div>
                    </div>
                    {(() => {
                        if (this.props.appointmentNews.logo) {
                            return <div id="logo">
                                <img src={this.props.appointmentNews.logo}  alt="Customer Logo"/>
                            </div>
                        }
                    })()}
                </div>
                <div className="footer">
                    <div className="row">
                        <div className="col-8">
                            <h1>{this.props.appointmentNews.customer}</h1>
                        </div>
                        <div className="col-4">
                            <ul>
                                <li className="location">{this.props.appointmentNews.locality}</li>
                                <li className="participants">{this.props.appointmentNews.participants.join(', ')}</li>
                            </ul>
                        </div>
                    </div>
                    <div className="bottom">
                        <b>{this.props.appointmentNews.type}</b>{bottomSeperator}{this.props.appointmentNews.description}
                    </div>
                </div>
            </div>
        );
    }
}

export default AppointmentNewsView