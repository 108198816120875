import React, { Component } from 'react';
import './Header.scss';
import {Nav, Navbar} from "react-bootstrap";
import {Link} from "react-router-dom";

class Header extends Component{

    getNavLinks() {
        let navLinks = [];

        if (this.props.roles?.includes('ROLE_N4_NEWS_ADMIN')) {
            navLinks.push({href: '/admin/news', label: 'News'});
            navLinks.push({href: '/admin/teams', label: 'Teams'});
        }
        if (this.props.roles?.includes('ROLE_KITCHEN')) {
            navLinks.push({href: '/admin/menu', label: 'Menü'});
        }

        return navLinks;
    }

    render(){
        const navLinks = this.getNavLinks().map((navLink, index) =>
            <Link key={index} className="nav-link" to={navLink.href}>{navLink.label}</Link>
        );

        return(
            <Navbar collapseOnSelect bg="dark" variant="dark" fixed="top" expand="lg">
                <Navbar.Brand href="/admin"><div className="headerlogo"></div></Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto">
                        {navLinks}
                    </Nav>
                    <Nav>
                        <Nav.Link href="/">Newsview</Nav.Link>
                        <Nav.Link onClick={this.props.onLogout}>Logout</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        )
    }
}

export default Header;
