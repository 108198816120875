import {createTheme, ThemeProvider} from "@mui/material/styles";
import axios from "axios";
import "moment/locale/de";
import {SnackbarProvider} from "notistack";
import React, {Component} from "react";
import {Route, Routes} from "react-router-dom";
import {ACCESS_TOKEN} from "../constants";
import {getSession} from "../util/APIUtils";
import Admin from "./admin/Admin";
import "./App.scss";
import Menu from "./admin/menu/Menu";
import News from "./admin/news/News";
import NewsUpload from "./admin/news/NewsUpload";
import Team from "./admin/teams/Team";
import Teams from "./admin/teams/Teams";
import NotFound from "./common/NotFound";
import {RequireAuth} from "./common/RequireAuth";
import Login from "./login/Login";
import NewsView from "./news-view/NewsView";

const N4Theme = createTheme({
    palette: {
        primary: {
            main: "#5CB8B2",
            dark: "#5CB8B2"
        },
        secondary: {
            main: "#AF5A37"
        }
    }
});

// Axios
if (localStorage.getItem(ACCESS_TOKEN)) {
    axios.defaults.headers.common["Authorization"] = "Basic " + localStorage.getItem(ACCESS_TOKEN);
}
axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

axios.interceptors.response.use(function (response) {
    // Do something with response data
    return response;
}, function (error) {
    // Do something with response error
    if (error.response?.status === 401 && !(window.location.pathname === '/login' || window.location.path === '/login')) {
        console.log('redir')
        window.location = "/login";
    } else {
        console.error(error);
        return Promise.reject(error);
    }
});

class App extends Component {
    constructor(props){
        super(props);
        this.state = {
            authenticated: true,
            loading: false,
            locations: [],
            session: []
        };

        this.loadCurrentSession = this.loadCurrentSession.bind(this);
    }

    componentDidMount(){
        this.loadCurrentSession();
        this.getInfos();
    }

    loadCurrentSession(){
        this.setState({
            loading:true
        });

        getSession()
        .then(response => {
            this.setState({
                authenticated: true,
                loading: false,
                session: response.data
            });
        })
        .catch(error => {
            this.setState({
                loading: false
            });
        });
    }

    getInfos() {
        axios.get('/api/location/labels').then(res => {
            const locations = res.data;
            this.setState(prevState => ({
                locations: locations
            }));
        });
    }

    render () {
        return (
            <ThemeProvider theme={N4Theme}>
                <SnackbarProvider maxSnack={3} anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right"
                }}>
                    <Routes>
                        <Route path="/login" element={<Login/>} />
                        <Route path="/admin" element={<Admin session={this.state.session}/>}>
                                <Route exact path="news"
                                        element={<RequireAuth authenticated={this.state?.authenticated}>
                                        <News roles={this.state?.session?.roles}/>
                                    </RequireAuth>}>
                                </Route>
                                <Route exact path="news/upload"
                                        element={<RequireAuth authenticated={this.state?.authenticated}>
                                        <NewsUpload locations={this.state?.locations} locationLabel={this.state?.session?.locationLabel}/>
                                    </RequireAuth>}>
                                </Route>
                                <Route exact path="news/edit/:newsId"
                                        element={<RequireAuth authenticated={this.state?.authenticated}>
                                        <NewsUpload/>
                                    </RequireAuth>}>
                                </Route>
                                <Route exact path="menu"
                                        element={<RequireAuth authenticated={this.state?.authenticated}>
                                        <Menu roles={this.state?.session?.roles}  locations={this.state?.locations} locationLabel={this.state?.session?.locationLabel}/>
                                    </RequireAuth>}>
                                </Route>
                                <Route exact path="teams"
                                        element={<RequireAuth authenticated={this.state?.authenticated}>
                                        <Teams/>
                                    </RequireAuth>}>
                                </Route>
                                <Route exact path="teams/add"
                                        element={<RequireAuth authenticated={this.state?.authenticated}>
                                        <Team/>
                                    </RequireAuth>}>
                                </Route>
                                <Route path="teams/edit/:teamId"
                                        element={<RequireAuth authenticated={this.state?.authenticated}>
                                        <Team/>
                                    </RequireAuth>}>
                                </Route>
                        </Route>
                        <Route path="/" element={<NewsView/>} />
                        <Route element={<NotFound />} />
                    </Routes>
                </SnackbarProvider>
            </ThemeProvider>
        );
    }
}

export default App;
