import {Button, TextField} from "@mui/material";
import axios from "axios";
import React, {Component} from "react";
import {ACCESS_TOKEN} from "../../constants";
import "./Login.scss";

class Login extends Component {
    constructor (props) {
        super(props);
        this.state = {
            username: false,
            password: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount () {
        const urlParams = new URLSearchParams(window.location.search);
        const username = urlParams.get("username");
        const password = urlParams.get("password");
        this.login(username, password);
    }

    handleChange = key => event => {
        const value = event.target.value;
        this.setState({
            [key]: value
        });
    };

    handleSubmit (event) {
        event.preventDefault();
        event.stopPropagation();
        this.login(this.state.username, this.state.password);
    }

    login (username, password) {
        if (username && password) {
            let data = username + ":" + password;
            let base64data =this.toBase64(data);
            localStorage.setItem(ACCESS_TOKEN, base64data);
            axios.defaults.headers.common = {
                "Authorization": "Basic " + base64data,
                "Content-Type": "application/json",
                "X-Requested-With": "XMLHttpRequest"
            };
            axios.get("/api/user/login").
                then(res => {
                    localStorage.setItem("role", res.data.roles);
                    switch (res.data.roles[0]) {
                    case "ROLE_N4_NEWS_ADMIN":
                        window.location = "/admin/news";
                        break;
                    case "ROLE_KITCHEN":
                        window.location = "/admin/menu";
                        break;
                    case "ROLE_NEWS_VIEW":
                    default:
                        window.location = "/";
                        break;
                    }
                }).
                catch(function (error) {
                    localStorage.removeItem(ACCESS_TOKEN);
                    console.error("login", error);
                });
        }
    }

    objToQueryString (location, token) {
        let keyValuePairs = [];
        keyValuePairs.push(encodeURIComponent("location") + "=" + encodeURIComponent(location));
        keyValuePairs.push(encodeURIComponent("emission") + "=" + encodeURIComponent("NEWS"));
        keyValuePairs.push(encodeURIComponent("token") + "=" + encodeURIComponent(token));
        return keyValuePairs.join("&");
    }

    render () {
        return (
            <div className="login-container">
                <div className="login">
                    <TextField
                        label="Username"
                        className={'mb-3'}
                        onChange={this.handleChange("username")}
                        fullWidth
                        required
                    />
                    <TextField
                        label="Password"
                        type="password"
                        onChange={this.handleChange("password")}
                        fullWidth
                        required
                    />
                    <br/><br/>
                    <Button variant="contained" color="primary"
                            onClick={this.handleSubmit}
                            disabled={!this.state.username || !this.state.password}>Submit</Button>
                </div>
            </div>
        );
    }
    toBase64(str) {
        return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
            (match, p1) => String.fromCharCode(Number('0x' + p1))));
    }

}

export default Login;
