import {DatePicker} from "@mui/x-date-pickers";
import React, {Component} from 'react';
import Cropper from 'react-cropper';

import 'cropperjs/dist/cropper.css';

import {Button, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Select, TextField} from '@mui/material';
import {CloudUpload} from '@mui/icons-material';
import CharacterCounter from "../../common/CharacterCounter";
import {TwitterPicker} from "react-color";

class AppointmentNews extends Component {
    constructor(props) {
        super(props);
        this.state = {
            news: this.props.news,
            scheduleTypes: [
                { value: 'CUSTOMER_APPOINTMENT', label: 'Kundentermin' }
            ],
            background:  {
                src: '',
                name: ''
            },
            logo: {
                src: '',
                name: ''
            }
        };

        this.onLogoUpload = this.onLogoUpload.bind(this);
        this.onBackgroundUpload = this.onBackgroundUpload.bind(this);
        this.cropImage = this.cropImage.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleNewsParticipantsChange = this.handleNewsParticipantsChange.bind(this);
        this.handleNewsChange = this.handleNewsChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {}

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if(this.state.news !== nextProps.news) {
            this.setState(prevState => ({
                news: {
                    ...prevState.news,
                    validFrom: nextProps.news.validFrom,
                    validTo: nextProps.news.validTo,
                    locations: nextProps.news.locations,
                    newsType: nextProps.news.newsType,
                }
            }));
        }
    }

    logoInput() {
        document.getElementById('logoInput').click();
    }

    backgroundInput() {
        document.getElementById('backgroundInput').click();
    }

    onLogoUpload(e) {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const reader = new FileReader();
        reader.readAsDataURL(files[0]);
        let formData = new FormData();
        formData.append("image", files[0], "image.png");
        reader.onload = () => {
            this.setState({logo: {name: files[0].name, src: reader.result}});
            this.setState(prevState => ({
                news: {
                    ...prevState.news,
                    logo: formData,
                }
            }));
        };
    }

    onBackgroundUpload(e) {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const reader = new FileReader();
        reader.onload = () => {
            this.setState({background: {name: files[0].name, src: reader.result}});
        };
        reader.readAsDataURL(files[0]);
    }

    handleDateChange(date) {
        this.setState(prevState => ({
            news: {
                ...prevState.news,
                date: date,
            }
        }));
    }

    handleNewsChange = key => event => {
        const value = event.target.value;
        this.setState(prevState => ({
            news: {
                ...prevState.news,
                [key]: value
            }
        }));
    }

    handleChangeBackgroundColor = (backgroundColor) => {
        this.setState(prevState => ({
            news: {
                ...prevState.news,
                backgroundColor: backgroundColor.hex
            }
        }));
    }

    handleNewsParticipantsChange = key => event => {
        const value = event.target.value.split(',').map(v => v.trim());
        this.setState(prevState => ({
            news: {
                ...prevState.news,
                participants: value
            }
        }));
    };

    cropImage() {
        return new Promise((resolve, reject) => {
            if (this.state.background.src) {
                if (typeof this.cropper.getCroppedCanvas() === 'undefined') {
                    reject(undefined)
                }
                this.cropper.getCroppedCanvas().toBlob(function(blob) {
                    let formData = new FormData();
                    formData.append("image", blob, "image.png");
                    resolve(formData)
                });
            } else {
                resolve(undefined)
            }
        })
    }

    getParticipants() {
        if (this.state.news.participants) {
            return this.state.news.participants.join(', ');
        }
        return undefined;
    }

    checkData() {
        return (!this.state.news.appointmentType || !this.state.news.date || !this.state.news.location)
    }

    handleSubmit(event) {
        let news = this.state.news;
        news.comment = news.appointmentType + ': ' + news.location;
        news.newsType = 'APPOINTMENT';
        this.cropImage().then((formData) => {
            news.background = formData;
            this.props.onSubmit(news);
        }).catch((error) => {
            console.error('cropImage', error);
            this.props.onSubmit(news);
        });
    }

    render() {
        let backgroundColor = this.state.news.backgroundColor ? this.state.news.backgroundColor : "#3B3B3C";
        return (
            <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                    <FormControl fullWidth required>
                        <InputLabel htmlFor="scheduleType">Termin Art</InputLabel>
                        <Select
                            defaultValue=""
                            value={this.state.news.appointmentType}
                            onChange={this.handleNewsChange('appointmentType')}
                            inputProps={{
                                name: 'scheduleType',
                                id: 'scheduleType',
                            }}>
                            {this.state.scheduleTypes.map((scheduleType) => <MenuItem value={scheduleType.value} key={scheduleType.value}>{scheduleType.label}</MenuItem>)}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <DatePicker
                        renderInput={(props) => <TextField {...props} />}
                        label="Datum"
                        value={this.state.news.date ? this.state.news.date : null}
                        onChange={date => this.handleDateChange(date)}
                        autoOk
                        showTodayButton
                        disableToolbar
                        fullWidth
                        required
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        label="Standort"
                        value={this.state.news.location}
                        onChange={this.handleNewsChange('location')}
                        fullWidth
                        required
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField
                        label="Besucher"
                        onChange={this.handleNewsParticipantsChange()}
                        value={this.getParticipants()}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        label="Firma"
                        value={this.state.news.company}
                        onChange={this.handleNewsChange('company')}
                        fullWidth
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        label="Beschreibung"
                        value={this.state.news.description}
                        onChange={this.handleNewsChange('description')}
                        fullWidth
                        multiline
                        minRows={2}
                        inputProps={{ maxLength: 200 }}
                    />
                    <CharacterCounter value={this.state.news.description} maxLength={200}></CharacterCounter>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <input id="logoInput" hidden type="file" onChange={this.onLogoUpload}/>
                    <TextField
                        label="Logo"
                        onClick={this.logoInput.bind(this)}
                        value={this.state.logo.name}
                        fullWidth
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton>
                                        <CloudUpload/>
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <input id="backgroundInput" hidden type="file" onChange={this.onBackgroundUpload}/>
                    <TextField
                        label="Hintergrund"
                        onClick={this.backgroundInput.bind(this)}
                        value={this.state.background.name}
                        fullWidth
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton>
                                        <CloudUpload/>
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>

                <Grid item xs={12} sm={8}>
                    <Cropper
                        src={this.state.background.src}
                        style={{height: 216, width: '100%'}}
                        aspectRatio={1440 / 1080} // 4:3
                        // viewMode={2}
                        guides={false}
                        preview=".img-preview"
                        onInitialized={cropper => this.cropper = cropper}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <div className="img-preview-container" style={{backgroundColor: backgroundColor}}>
                        <div className="preview16to9"></div>
                        <div className="img-preview">
                            {this.state.news.backgroundSrc ? <div className="cropper-img"><img src={'/api/news/image/' + this.state.news.backgroundSrc} alt='cropped'/></div> : ''}
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <TwitterPicker
                       color={ backgroundColor }
                       onChangeComplete={ this.handleChangeBackgroundColor }
                       width="100%"
                       triangle="top-right"
                       colors={['#5CB8B2', '#008580','#BBD6B5', '#F8C135', '#FA7800', '#BD4B11','#3C3C3B', '#F3F3F3', '#FFF']}
                    />
                </Grid>

                <Grid item xs={12} className='text-right'>
                    <Button variant="contained" color="primary" onClick={this.handleSubmit} disabled={this.checkData()}>Speichern</Button>
                </Grid>
            </Grid>
        )
    }
}

export default AppointmentNews
