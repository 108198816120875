import React, { Component } from 'react';

import Moment from "react-moment";

class GraduationNewsView extends Component{
    render(){
        return(
            <div id="graduation-news-view" className="news" style={this.props.graduationNews.backgroundColor ? {backgroundColor: this.props.graduationNews.backgroundColor} : {}}>
                <div className="content">
                    <div className="background"></div>
                    <div id="graduation-news-details">
                        <h1>Herzlichen Glückwunsch!<br/><n>an {this.props.graduationNews.employee.firstName}!</n></h1>

                        <p>{this.props.graduationNews.employee.firstName} (Team <i>{this.props.graduationNews.team.name}</i> ) hat am <i><Moment format="DD.MM.YYYY" date={this.props.graduationNews.graduationDate}/></i> den {this.props.graduationNews.type} an der {this.props.graduationNews.university} im Studiengang {this.props.graduationNews.course} abgeschlossen.</p>

                        <img id="team-emblem" src={'api/team/image/' + this.props.graduationNews.team.logoUrl} width="80" alt={this.props.graduationNews.team.name} />
                    </div>
                </div>
            </div>
        );
    }
}

export default GraduationNewsView