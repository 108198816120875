import React, {Component} from 'react';

import {Button, Grid, TextField, Select, MenuItem, InputLabel, FormControl} from '@mui/material';
import {DatePicker} from '@mui/x-date-pickers';
import CharacterCounter from "../../common/CharacterCounter";
import axios from "axios";

class TeamTripNews extends Component {
    constructor(props) {
        super(props);
        this.state = {
            news: this.props.news,
            locations: [
                {value: 'SAARBRUECKEN', label: 'Saarbrücken'},
                {value: 'SCHEIDT', label: 'Scheidt'},
                {value: 'LEIPZIG', label: 'Leipzig'}
            ],
            teams: []
        };

        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleNewsChange = this.handleNewsChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleTeam = this.handleTeam.bind(this);
    }

    componentDidMount() {
        this.getInfos();
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if(this.state.news !== nextProps.news) {
            this.setState(prevState => ({
                news: {
                    ...prevState.news,
                    validFrom: nextProps.news.validFrom,
                    validTo: nextProps.news.validTo,
                    locations: nextProps.news.locations,
                    newsType: nextProps.news.newsType,
                }
            }));
        }
    }

    getInfos() {
        axios.get('/api/team').then(res => {
            const teams = res.data;
            this.setState(prevState => ({
                teams: teams
            }));
        });
    }

    handleDateChange(date) {
        this.setState(prevState => ({
            news: {
                ...prevState.news,
                date: date,
            }
        }));
    }

    handleNewsChange = key => event => {
        const value = event.target.value;
        this.setState(prevState => ({
            news: {
                ...prevState.news,
                [key]: value
            }
        }));
    }

    handleTeam = key => event => {
        const team = this.state.teams.find(team => team.id === event.target.value);
        this.setState(prevState => ({
            news: {
                ...prevState.news,
                team: team
            }
        }));
    }

    handleSubmit(event) {
        let news = this.state.news;
        news.comment = news.team.name;
        news.newsType = 'TEAM_TRIP';
        this.props.onSubmit(news);
    }

    checkData() {
        return (!this.state.news.team || !this.state.news.title || !this.state.news.date)
    }

    render() {
        return (
            <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                    <TextField
                        label="Titel"
                        value={this.state.news.title}
                        onChange={this.handleNewsChange('title')}
                        inputProps={{ maxLength: 25 }}
                        fullWidth
                        required
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <FormControl fullWidth required>
                        <InputLabel htmlFor="team">Team</InputLabel>
                        <Select
                           defaultValue=""
                           inputProps={{
                               name: 'team',
                               id: 'team',
                           }}
                           value={this.state.news.team?.id}
                           onChange={this.handleTeam()}>
                            {this.state.teams.map((team) => <MenuItem value={team.id} key={team.id}>{team.name}</MenuItem>)}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <DatePicker
                       renderInput={(props) => <TextField {...props} />}
                       label="Datum"
                       value={this.state.news.date ? this.state.news.date : null}
                       onChange={date => this.handleDateChange(date)}
                       autoOk
                       showTodayButton
                       disableToolbar
                       fullWidth
                       required/>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <TextField
                        label="Beschreibung"
                        value={this.state.news.description}
                        onChange={this.handleNewsChange('description')}
                        inputProps={{ maxLength: 255 }}
                        fullWidth
                    />
                    <CharacterCounter value={this.state.news.description} maxLength={255}></CharacterCounter>
                </Grid>

                <Grid item xs={12} className='text-right'>
                    <Button variant="contained" color="primary" onClick={this.handleSubmit} disabled={this.checkData()}>Speichern</Button>
                </Grid>
            </Grid>
        )
    }
}

export default TeamTripNews
