import React, { Component } from 'react';

class DefaultNewsView extends Component{
    render(){
        return(
            <div id="default-news-view" className="news" style={this.props.defaultNews.backgroundColor ? {backgroundColor: this.props.defaultNews.backgroundColor} : {}}>
                <div className="content">
                    <div className="background" style={this.props.defaultNews.image ? {backgroundImage: 'url(/api/news/image/' + this.props.defaultNews.image + ')'} : {}}>
                        <div className="gardient"></div>
                    </div>
                </div>
                {(() => {
                    if (this.props.defaultNews.title) {
                        return <div className="footer">
                            <h1>{this.props.defaultNews.title}</h1>
                            <span>{this.props.defaultNews.description}</span>
                        </div>
                    }
                })()}
            </div>
        );
    }
}

export default DefaultNewsView