import React, { Component } from 'react';

import './Sidebar.scss';

import Menu from "./Menu";
import DateTime from "./DateTime";
import Weather from "./Weather";
import axios from "axios";
import {getSession} from "../../../util/APIUtils";
import Grid from "@mui/material/Grid";

class Sidebar extends Component{
    constructor(props) {
        super(props);
        this.state = {
            clientHeight: 0,
            menu: undefined,
            weather: undefined,
            date: new Date()
        };
    }

    componentDidMount() {
        this.getHeight();
        this.getMenu();
        this.getWeather();

        //keep 'weather' and "menu" up to date
        this.interval = setInterval(() => {
            getSession().then(response => {
                console.log('session updated', new Date())
            });
            this.getMenu();
            this.getWeather();
        }, 900000);

        //keep 'date' up to date
        this.interval = setInterval(() => {
            this.setState({date: new Date() });
        }, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    getHeight() {
        const clientHeight = document.body.clientHeight;
        this.setState({ clientHeight });
    }

    getMenu() {
        axios.get('/api/menu').then(res => {
            const menu = res.data;
            this.setState(prevState => ({
                ...prevState,
                menu: menu
            }));
        });
    }

    getWeather() {
        axios.get('/api/weather').then(res => {
            const weather = res.data;
            this.setState(prevState => ({
                ...prevState,
                weather: weather
            }));
        });
    }

    render(){
        return(
            <Grid item id="sidebar" xs={12} lg={3}>
                <Menu menu={this.state.menu} date={this.state.date}/>
                <Weather weather={this.state.weather} date={this.state.date}/>
                <DateTime date={this.state.date}/>
            </Grid>
        );
    }
}

export default Sidebar
