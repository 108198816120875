import React, { Component } from 'react';
import Moment from "react-moment";

class Menu extends Component{
    render() {
        const separator = " <highlight>/</highlight> ";
        if (this.props.menu && (this.props.menu.appetizer.length > 0 || this.props.menu.mainCourse.length > 0 || this.props.menu.dessert.length > 0)) {
            return(
                <section id="menu">
                    <div className="cantina-band"></div>
                    <div className="content">
                        <h2><Moment format="dddd" locale="de" date={this.props.date}/><i>menü</i></h2>
                        <p dangerouslySetInnerHTML={{__html: this.props.menu.appetizer.join(separator)}}></p>
                        <p dangerouslySetInnerHTML={{__html: this.props.menu.mainCourse.join(separator)}}></p>
                        <p dangerouslySetInnerHTML={{__html: this.props.menu.dessert.join(separator)}}></p>
                    </div>
                </section>
            );
        } else {
            return(
                <section id="menu" className="coming-soon">
                    <h2>Speiseplan<i>coming soon ...</i></h2>
                </section>
            );
        }
    }
}

export default Menu