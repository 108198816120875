import {CloudUpload} from '@mui/icons-material';
import Cropper from "react-cropper";
import React, {Component} from 'react';
import {Grid, IconButton, InputAdornment, TextField, Button, MenuItem, FormControl, InputLabel, Select} from '@mui/material';
import axios from "axios";
import {TwitterPicker} from "react-color";

class ProjectCompletionNews extends Component{
    constructor(props) {
        super(props);
        this.state = {
            news: this.props.news,
            background:  {
                src: '',
                name: ''
            },
            teams: [],
        };

        this.onBackgroundUpload = this.onBackgroundUpload.bind(this);
        this.cropImage = this.cropImage.bind(this);
        this.handleNewsChange = this.handleNewsChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleTeam = this.handleTeam.bind(this);
    }

    componentDidMount() {
        this.getInfos();
    }

    getInfos() {
        axios.get('/api/team').then(res => {
            const teams = res.data;
            this.setState(prevState => ({
                teams: teams
            }));
        });
    }

    cropImage() {
        return new Promise((resolve, reject) => {
            if (this.state.background.src) {
                if (typeof this.cropper.getCroppedCanvas() === 'undefined') {
                    reject(undefined)
                }
                this.cropper.getCroppedCanvas().toBlob(function(blob) {
                    let formData = new FormData();
                    formData.append("image", blob, "image.png");
                    resolve(formData)
                });
            } else {
                resolve(undefined)
            }
        })
    }

    backgroundInput() {
        document.getElementById('backgroundInput').click();
    }

    onBackgroundUpload(e) {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = () => {
            this.setState({background: {name: files[0].name, src: reader.result}});
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if(this.state.news !== nextProps.news) {
            this.setState(prevState => ({
                news: {
                    ...prevState.news,
                    validFrom: nextProps.news.validFrom,
                    validTo: nextProps.news.validTo,
                    locations: nextProps.news.locations,
                    newsType: nextProps.news.newsType,
                }
            }));
        }
    }

    handleNewsChange = key => event => {
        const value = event.target.value;
        this.setState(prevState => ({
            news: {
                ...prevState.news,
                [key]: value
            }
        }));
    }

    handleChangeBackgroundColor = (backgroundColor) => {
        this.setState(prevState => ({
            news: {
                ...prevState.news,
                backgroundColor: backgroundColor.hex
            }
        }));
    }

    handleTeam = key => event => {
        const team = this.state.teams.find(team => team.id === event.target.value);
        this.setState(prevState => ({
            news: {
                ...prevState.news,
                team: team
            }
        }));
    }

    checkData() {
        return (!this.state.news.name || !this.state.news.team)
    }

    handleSubmit(event) {
        let news = this.state.news;
        news.comment = news.name;
        news.newsType = 'PROJECT_COMPLETION';
        this.cropImage().then((formData) => {
            news.background = formData;
            this.props.onSubmit(news);
        }).catch((error) => {
            console.error('cropImage', error);
            this.props.onSubmit(news);
        });
    }

    render() {
        let backgroundColor = this.state.news.backgroundColor ? this.state.news.backgroundColor : "#3B3B3C";
        return (
            <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                    <TextField
                        label="Name"
                        placeholder="Project name"
                        onChange={this.handleNewsChange('name')}
                        value={this.state.news.name}
                        fullWidth
                        required
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <FormControl fullWidth required>
                        <InputLabel htmlFor="team">Team</InputLabel>
                        <Select
                            defaultValue=""
                            inputProps={{
                                name: 'team',
                                id: 'team',
                            }}
                            value={this.state.news.team?.id}
                            onChange={this.handleTeam()}>
                            {this.state.teams.map((team) => <MenuItem value={team.id} key={team.id}>{team.name}</MenuItem>)}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <input id="backgroundInput" hidden type="file" onChange={this.onBackgroundUpload}/>
                    <TextField
                        label="Hintergrundbild"
                        onClick={this.backgroundInput.bind(this)}
                        value={this.state.background.name}
                        fullWidth
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton>
                                        <CloudUpload/>
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>

                <Grid item xs={12} sm={12}>
                    <TextField
                        label="Beschreibung"
                        onChange={this.handleNewsChange('description')}
                        value={this.state.news.description}
                        fullWidth
                        multiline
                        minRows={4}
                    />
                </Grid>

                <Grid item xs={12} sm={8}>
                    <Cropper
                        src={this.state.background.src}
                        style={{height: 216, width: '100%'}}
                        aspectRatio={1440 / 1080} // 4:3
                        // viewMode={2}
                        guides={false}
                        preview=".img-preview"
                        onInitialized={cropper => this.cropper = cropper}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <div className="img-preview-container" style={{backgroundColor: backgroundColor}}>
                        <div className="preview16to9"></div>
                        <div className="img-preview">
                            {this.state.news.backgroundSrc ? <div className="cropper-img"><img style={{width: '100%'}} src={'/api/news/image/' + this.state.news.backgroundSrc} alt='cropped'/></div> : ''}
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <TwitterPicker
                       color={ backgroundColor }
                       onChangeComplete={ this.handleChangeBackgroundColor }
                       width="100%"
                       triangle="top-right"
                       colors={['#5CB8B2', '#008580','#BBD6B5', '#F8C135', '#FA7800', '#BD4B11','#3C3C3B', '#F3F3F3', '#FFF']}
                    />
                </Grid>

                <Grid item xs={12} className='text-right'>
                    <Button variant="contained" color="primary" onClick={this.handleSubmit} disabled={this.checkData()}>Speichern</Button>
                </Grid>
            </Grid>
        );
    }
}

export default ProjectCompletionNews
