import React, { Component } from 'react';

// import Moment from 'react-moment/dist/index';
// import TeamEmblem from "../../common/TeamEmblem";

class TeamTripNewsView extends Component{
    render(){
        /*let location = undefined;
        switch (this.props.teamTripsNews.location) {
            case 'SAARBRUECKEN':
                location = "Saarbrücken";
                break;
            case 'SCHEIDT':
                location = "Scheidt";
                break;
            case 'LEIPZIG':
                location = "Leipzig";
                break;
            default: break;
        }*/
        return(
            <div id="team-trip-news-view" className="news" style={this.props.teamTripsNews.backgroundColor ? {backgroundColor: this.props.teamTripsNews.backgroundColor} : {}}>
               <div className="content">
                        <h1><i>Teamausflug</i></h1>
                        <div className="row" id="colleague-details">
                            <div className="col-12">
                                <h1>{this.props.teamTripsNews.team.name} waren unterwegs!</h1>
                            </div>
                            <div className="title" >
                                <h2>{this.props.teamTripsNews.title}</h2>
                                <span>{this.props.teamTripsNews.description}</span>
                            </div>
                            <div className="col-4">
                                <img id="team-emblem" src={'api/team/image/' + this.props.teamTripsNews.team.logo} width="80" alt={this.props.teamTripsNews.team.name} />
                            </div>
                        </div>
                    </div>
                </div>
        );
    }
}

export default TeamTripNewsView