import React, {Component} from 'react';

class GreetingNewsView extends Component{

    render(){

        return(
            <div id="greeting-news-view" className="news" style={this.props.greetingNews.backgroundColor ? {backgroundColor: this.props.greetingNews.backgroundColor} : {}}>
                <div className="content">
                    <div className="background" style={this.props.greetingNews.image ? {backgroundImage: 'url(/api/news/image/' + this.props.greetingNews.image + ')'} : {}}>
                        <div className="gardient"></div>
                    </div>
                    <div className="logo">
                        {(this.props.greetingNews.logo) ? <img src={'/api/news/image/' + this.props.greetingNews.logo} alt={this.props.greetingNews.name}/> : <h2>{this.props.greetingNews.name}</h2>}
                    </div>
                    <h1>Herzlich <n>Willkommen</n></h1>
                </div>
            </div>
        );
    }
}

export default GreetingNewsView