import React, {Component} from 'react';

import axios from "axios";
import {Button, Fab, Grid, Table, TableBody, TableCell, TableRow, TextField, FormControl, InputLabel, MenuItem, Select} from '@mui/material';
import {DateTimePicker} from "@mui/x-date-pickers";
import {Delete, Edit, Save} from '@mui/icons-material';

class RapidTechTalkNews extends Component {
    constructor(props) {
        super(props);
        this.state = {
            news: this.props.news,
            employees : [],
            topic: {
                speaker: undefined,
                title: undefined
            },
            editedTopic: {
                speaker: undefined,
                title: undefined
            }
        };

        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleAddTopic = this.handleAddTopic.bind(this);
        this.handleDeleteTopic = this.handleDeleteTopic.bind(this);
        this.handleEditTopic = this.handleEditTopic.bind(this);
        this.handleNewsChange = this.handleNewsChange.bind(this);
        this.handleTopicChange = this.handleTopicChange.bind(this);
        this.handleEditedTopicChange = this.handleEditedTopicChange.bind(this);
        this.handleSpeaker = this.handleSpeaker.bind(this);
    }

    componentDidMount() {
        this.getInfos();
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if(this.state.news !== nextProps.news) {
            this.setState(prevState => ({
                news: {
                    ...prevState.news,
                    validFrom: nextProps.news.validFrom,
                    validTo: nextProps.news.validTo,
                    locations: nextProps.news.locations,
                    newsType: nextProps.news.newsType,
                }
            }));
        }
    }

    getInfos() {
        axios.get('/api/employee').then(res => {
            const employees = res.data;
            this.setState(prevState => ({
                employees: employees
            }));
        });
    }


    handleDateChange(date) {
        this.setState(prevState => ({
            news: {
                ...prevState.news,
                date: date.format("YYYY-MM-DDTHH:mm:ss")
            }
        }));
    }

    handleNewsChange = key => event => {
        const value = event.target.value;
        this.setState(prevState => ({
            news: {
                ...prevState.news,
                [key]: value
            }
        }));
    }

    handleSpeaker = key => event => {
        const speaker = event.target.value;
        this.setState(prevState => ({
            topic: {
                ...prevState.topic,
                speaker: speaker
            }
        }));
    }

    handleTopicChange = key => event => {
        const value = event.target.value;
        this.setState(prevState => ({
            topic: {
                ...prevState.topic,
                [key]: value
            }
        }));
    }

    handleEditedTopicChange = key => event => {
        const value = event.target.value;
        this.setState(prevState => ({
            editedTopic: {
                ...prevState.editedTopic,
                [key]: value
            }
        }));
    }

    handleAddTopic() {
        let topics = this.state.news.topics ? this.state.news.topics : [];
        topics.push({speaker: this.state.topic.speaker, title: this.state.topic.title});
        this.setState(prevState => ({
            news: {
                ...prevState.news,
                topics: topics
            },
            topic: {
                speaker: '',
                title: ''
            }
        }));
    }

    handleDeleteTopic(index) {
        let topics = this.state.news.topics;
        topics.splice(index, 1);
        this.setState(prevState => ({
            news: {
                ...prevState.news,
                topics: topics
            }
        }));
    }

    handleEditTopic(index, mode) {
        if(mode === 'edit') {
            let editedTopic = this.state.news.topics[index];
            editedTopic.index = index;
            this.setState(prevState => ({
                editedTopic: editedTopic
            }));
        } else {
            let topics = this.state.news.topics;
            topics[index].speaker = this.state.editedTopic.speaker;
            topics[index].title = this.state.editedTopic.title;
            this.setState(prevState => ({
                news: {
                    ...prevState.news,
                    topics: topics
                },
                editedTopic: {
                    index: undefined
                }
            }));
        }
    }

    handleSubmit() {
        let news = this.state.news;
        news.comment = new Date(news.date).toLocaleDateString();
        news.newsType = 'RAPID_TECH_TALK';
        news.spot = 'Online';
        this.props.onSubmit(news);
    }

    checkData() {
        return (!this.state.news.date || !this.state.news.topics || this.state.news.topics.length === 0)
    }

    render() {
        let topics = this.state.news.topics ? this.state.news.topics : [];
        return (
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <DateTimePicker
                        variant="inline"
                        label="Datum"
                        value={this.state.news.date ? this.state.news.date : null}
                        onChange={date => this.handleDateChange(date)}
                        autoOk
                        fullWidth
                        required
                    />
                </Grid>

                <Grid item xs={12}>
                    <h2>Vorträge</h2>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <FormControl fullWidth>
                        <InputLabel htmlFor="speaker">Sprecher</InputLabel>
                        <Select
                            defaultValue=""
                            inputProps={{
                                name: 'speaker',
                                id: 'speaker',
                            }}
                            value={this.state.topic.speaker}
                            onChange={this.handleSpeaker()}>
                            {this.state.employees.map((employee) => <MenuItem value={employee} key={employee.id}>{employee.firstName} {employee.lastName}</MenuItem>)}
                        </Select>
                        </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        label="Titel"
                        value={this.state.topic.title}
                        onChange={this.handleTopicChange('title')}
                        inputProps={{ maxLength: 40 }}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Button variant="contained" color="primary" className="w-100" onClick={this.handleAddTopic}>Hinzufügen</Button>
                </Grid>

                <Grid item xs={12}>
                    <Table aria-label="topic table">
                        <TableBody>
                            {topics.map((topic, index) => {
                                if(this.state.editedTopic.index !== index) {
                                    return <TableRow>
                                        <TableCell>{topic.speaker.firstName} {topic.speaker.lastName} </TableCell>
                                        <TableCell>{topic.title}</TableCell>
                                        <TableCell align="right">
                                            <Fab size="small" className="fab" color="primary" aria-label="Edit" onClick={() => this.handleEditTopic(index, 'edit')}>
                                                <Edit />
                                            </Fab>
                                            <Fab size="small" className="fab" color="secondary" aria-label="Delete" onClick={() => this.handleDeleteTopic(index)}>
                                                <Delete />
                                            </Fab>
                                        </TableCell>
                                    </TableRow>
                                } else {
                                    return <TableRow>
                                        <TableCell>
                                            <TextField
                                                label="Sprecher"
                                                value={this.state.editedTopic.speaker}
                                                onChange={this.handleEditedTopicChange('speaker')}
                                                inputProps={{ maxLength: 15 }}
                                                fullWidth
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                label="Titel"
                                                value={this.state.editedTopic.title}
                                                onChange={this.handleEditedTopicChange('title')}
                                                inputProps={{ maxLength: 40 }}
                                                fullWidth
                                            />
                                        </TableCell>
                                        <TableCell align="right">
                                            <Fab size="small" className="fab" color="primary" aria-label="Edit" onClick={() => this.handleEditTopic(index, 'save')}>
                                                <Save />
                                            </Fab>
                                            <Fab size="small" className="fab" color="secondary" aria-label="Delete" onClick={() => this.handleDeleteTopic(index)}>
                                                <Delete />
                                            </Fab>
                                        </TableCell>
                                    </TableRow>
                                }
                            })}
                        </TableBody>
                    </Table>
                </Grid>

                <Grid item xs={12} className='text-right'>
                    <Button variant="contained" color="primary" onClick={this.handleSubmit} disabled={this.checkData()}>Speichern</Button>
                </Grid>
            </Grid>
        )
    }
}

export default RapidTechTalkNews
