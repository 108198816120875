import React, { Component } from 'react';
import {Fab, Table, TableBody, TableCell, TableRow} from "@mui/material";
import {Add, Delete, Edit} from '@mui/icons-material';
import axios from "axios";
import {withRouter} from "../../common/withRouter";

class Teams extends Component{
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            teams: []
        };
    }

    componentDidMount() {
        this._isMounted = true;
        this.getTeams();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    async getTeams() {
        const me = this;
        axios.get('/api/team').then(res => {
            const teams = res.data;
            if (me._isMounted) {
                me.setState(prevState => ({
                    ...prevState,
                    teams: teams
                }));
            }
        });
    }

    handleDelete(teamId) {
        axios.delete('/api/team/'+teamId).then(res => this.getTeams());
    }

    translateLocation(locationLabel){
        switch (locationLabel) {
            case 'SCHEIDT':
              return 'Scheidt';
            case 'SAARBRUECKEN':
              return 'Saarbrücken';
            case 'LEIPZIG':
                return 'Leipzig';
            default:
                return '';
          }
    }

    render() {
        return(
            <div id="teams">
                <h1>Teams</h1>

                <Table aria-label="simple table">
                    <TableBody>
                        {this.state.teams.map(team => (
                            <TableRow key={team.id}>
                                {
                                <TableCell><img src={'/api/team/image/' +team.logo} alt={team.teamLabel} width="80"/></TableCell>}
                                <TableCell>{team.name}</TableCell>
                                <TableCell>{this.translateLocation(team.locationLabel)}</TableCell>
                                <TableCell align="right">
                                    <Fab size="small" className="fab" color="primary" aria-label="Edit" onClick={() => this.props.router.navigate('./edit/' + team.id)}>
                                        <Edit />
                                    </Fab>
                                    <Fab size="small" className="fab" color="secondary" aria-label="Delete" onClick={() => this.handleDelete(team.id)}>
                                        <Delete />
                                    </Fab>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>

                <Fab color="primary" className="fab-add" aria-label="add" onClick={() => this.props.router.navigate('./add')}>
                    <Add/>
                </Fab>
            </div>
        );
    }
}

export default withRouter(Teams)
