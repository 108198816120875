import React, { Component } from 'react';
import Moment from "react-moment";

class RapidTechTalkNewsView extends Component{
    render(){
        const date = this.props.rapidTechTalkNews.date;
        return(
            <div id="rapid-tech-talk-news-view" className="news" style={this.props.rapidTechTalkNews.backgroundColor ? {backgroundColor: this.props.rapidTechTalkNews.backgroundColor} : {}}>
                <div className="content">
                    <div id="date">
                        <h2><Moment format="dddd" date={date}/> <i>/</i> <n><Moment format="DD.MM.YYYY" date={date}/></n> <i>/</i> <n><Moment format="HH:mm" date={date}/> Uhr</n></h2>
                    </div>
                    <ul id="themes">
                        {this.props.rapidTechTalkNews.topics.map(topic => (
                            <li className="theme">
                                <b className="highlight">{topic.speaker.firstName}</b>{topic.title}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        );
    }
}

export default RapidTechTalkNewsView