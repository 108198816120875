import React, { Component } from 'react';
import Moment from "react-moment";

import Clear from './svg/weather/clear.svg';
import Clouds from './svg/weather/clouds.svg';
import Rain from './svg/weather/rain.svg';
import Snow from './svg/weather/snow.svg';
import Thunderstorm from './svg/weather/thunderstorm.svg';

class WeatherIcon extends Component {
    render() {
        switch (this.props.kind) {
            case 'Clear':
                return <div>
                    <p className="icon">
                        <img src={Clear} alt={this.props.kind}/>
                    </p>
                    <p className="temperature">{this.props.temperature}°C</p>
                    <i>sonnig</i>
                </div>;
            case 'Clouds':
                return <div>
                    <p className="icon">
                        <img src={Clouds} alt={this.props.kind}/>
                    </p>
                    <p className="temperature">{this.props.temperature}°C</p>
                    <i>bewölkt</i>
                </div>;
            case 'Drizzle':
                return <div>
                    <p className="icon">
                        <img src={Rain} alt={this.props.kind}/>
                    </p>
                    <p className="temperature">{this.props.temperature}°C</p>
                    <i>schauer</i>
                </div>;
            case 'Rain':
                return <div>
                    <p className="icon">
                        <img src={Rain} alt={this.props.kind}/>
                    </p>
                    <p className="temperature">{this.props.temperature}°C</p>
                    <i>regen</i>
                </div>;
            case 'Snow':
                return <div>
                    <p className="icon">
                        <img src={Snow} alt={this.props.kind}/>
                    </p>
                    <p className="temperature">{this.props.temperature}°C</p>
                    <i>schnee</i>
                </div>;
            case 'Thunderstorm':
                return <div>
                    <p className="icon">
                        <img src={Thunderstorm} alt={this.props.kind}/>
                    </p>
                    <p className="temperature">{this.props.temperature}°C</p>
                    <i>gewitter</i>
                </div>;
            default:
                return <div>
                    <p className="icon"></p>
                    <p className="temperature">{this.props.temperature}°C</p>
                    <i>{this.props.kind}</i>
                </div>;
        }
    }
}

class Weather extends Component{
    render() {
        if (this.props.weather) {
            return(
                <section id="weather">
                    <div className="day">
                        <p><b>Heute</b></p>
                        <WeatherIcon temperature={this.props.weather.today.temperature} kind={this.props.weather.today.kind}/>
                    </div>
                    <div className="day">
                        <p><Moment format="dd" locale="de" date={this.props.date}  add={{ days: 1 }}/></p>
                        <WeatherIcon temperature={this.props.weather.tomorrow.temperature} kind={this.props.weather.tomorrow.kind}/>
                    </div>
                    <div className="day">
                        <p><Moment format="dd" locale="de" date={this.props.date}  add={{ days: 2 }}/></p>
                        <WeatherIcon temperature={this.props.weather.dayAfterTomorrow.temperature} kind={this.props.weather.dayAfterTomorrow.kind}/>
                    </div>
                </section>
            );
        } else {
            return(
                <section id="weather" className="coming-soon">
                    <h2>Wetter<i>coming soon ...</i></h2>
                </section>
            );
        }

    }
}

export default Weather