import React, {Component} from 'react';
import {Fab, Table, TableBody, TableCell, TableRow} from "@mui/material";
import {Add, Delete, Edit} from '@mui/icons-material';
import axios from "axios";
import Moment from "react-moment";
import './News.scss';
import {withRouter} from "../../common/withRouter";

class News extends Component{
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            news: []
        };
    }

    componentDidMount() {
        this._isMounted = true;
        this.getNews();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getNews() {
        const me = this;
        axios.get('/api/news').then(res => {
            const news = res.data;
            if (me._isMounted) {
                me.setState({
                    news,
                });
            }
        }).catch(function (error) {
            console.error(error);
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if(nextProps.news && this.state.news !== nextProps.news) {
            this.setState(prevState => ({
                news: {
                    ...prevState.news,
                    validFrom: nextProps.news.validFrom,
                    validTo: nextProps.news.validTo,
                    locations: nextProps.news.locations,
                    newsType: nextProps.news.newsType,
                }
            }));
        }
    }

    handleDelete(newsId) {
        axios.delete('/api/news/' + newsId).then(res => {
            this.getNews()
        });
    }

    translateLocations(locations){
        const locationsTranslated = [];
        locations.forEach(function(locationLabel){
        switch (locationLabel) {
            case 'SCHEIDT':
                locationsTranslated.push('Scheidt');
                break;
            case 'SAARBRUECKEN':
                locationsTranslated.push('Saarbrücken');
                break;
            case 'LEIPZIG':
                locationsTranslated.push('Leipzig');
                break;
            default:
                locationsTranslated.push('');
                break;
          }
        });
        return locationsTranslated;
    }

    translateNewsType(newsType){
        switch (newsType) {
            case 'DEFAULT':
              return 'Standard';
            case 'GRADUATION':
              return 'Abschluss';
            case 'GREETING':
                  return 'Begrüßung';
            case 'NEW_COLLEAGUE':
                return 'Neuer Kollege';
            case 'PROJECT_COMPLETION':
                return 'Projektabschluss';
            case 'RAPID_TECH_TALK':
                return 'Rapid tech talk';
            case 'TEAM_TRIP':
                return 'Teamausflüge';
            case 'APPOINTMENT':
                return 'Termin';
            default:
                return '';
          }
    }

    render(){
        console.log(this.props)
        if (this.props.roles?.includes("ROLE_N4_NEWS_ADMIN")) {
            return (
               <div id="news">
                   <h1>News</h1>
                   <div className="alert alert-info mt-2">Die Aktualisierung kann bis zu 5 Minuten dauern.</div>
                   <Table aria-label="simple table">
                       <TableBody>
                           {this.state.news.map(news => (
                              <TableRow key={news.id}>
                                  <TableCell>
                                      {(() => {
                                          if (news.backgroundSrc) {
                                              return <img src={'/api/news/image/' + news.backgroundSrc} alt={news.comment}/>
                                          }
                                      })()}
                                  </TableCell>
                                  <TableCell>{this.translateNewsType(news.newsType)}</TableCell>
                                  <TableCell>{news.comment}</TableCell>
                                  <TableCell>{this.translateLocations(news.locations).join(', ')}</TableCell>
                                  <TableCell>
                                      <Moment locale="de" format="dd Do MMMM" date={news.validFrom}></Moment>
                                  </TableCell>
                                  <TableCell>
                                      <Moment locale="de" format="dd Do MMMM" date={news.validTo}></Moment>
                                  </TableCell>
                                  <TableCell align="right">
                                      <Fab size="small" className="fab" color="primary" aria-label="Edit"
                                           onClick={() => this.props.router.navigate('edit/' + news.id)}>
                                          <Edit/>
                                      </Fab>
                                      <Fab size="small" className="fab" color="secondary" aria-label="Delete"
                                           onClick={() => this.handleDelete(news.id)}>
                                          <Delete/>
                                      </Fab>
                                  </TableCell>
                              </TableRow>
                           ))}
                       </TableBody>
                   </Table>

                   <Fab color="primary" className="fab-add" aria-label="add"
                        onClick={() => this.props.router.navigate('upload')}>
                       <Add/>
                   </Fab>
               </div>
            );
        } else {
            return <div className="alert alert-danger mt-2">Keine Berechtigung!</div>
        }
    }
}

export default withRouter(News);
