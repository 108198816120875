import React, { Component } from 'react';
import {Checkbox, Button, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import axios from "axios";
import {CloudUpload} from "@mui/icons-material";
import { enqueueSnackbar } from 'notistack'
import {withRouter} from "../../common/withRouter";

class Team extends Component{
    constructor(props) {
        super(props);
        this.state = {
            team: {
                employees: []
            },
            employees: [],
            locations: [
                {value: 'SAARBRUECKEN', label: 'Saarbrücken'},
                {value: 'SCHEIDT', label: 'Scheidt'},
                {value: 'LEIPZIG', label: 'Leipzig'}
            ],
            logo: {
                src: '',
                name: ''
            }
        };

        this.onLogoUpload = this.onLogoUpload.bind(this);
        this.handleTeamChange = this.handleTeamChange.bind(this);
        this.handleEmployeeSelection = this.handleEmployeeSelection.bind(this);
        this.handleSave = this.handleSave.bind(this);
    }


    componentDidMount() {
        if (this.props.router.params.teamId) {
            this.getTeam(this.props.router.params.teamId);
        }
        this.getInfos();
    }

    getInfos() {
        axios.get('/api/employee').then(res => {
            const employees = res.data;
            this.setState(prevState => ({
                employees: employees
            }));
        });
    }

    handleTeamChange = key => event => {
        const value = event.target.value;
        this.setState(prevState => ({
            team: {
                ...prevState.team,
                [key]: value
            }
        }));
    };

    handleEmployeeSelection(selection) {
        let employees = selection.target.value;
        var e = this.state.employees.length;
        let employeeId = employees[e];
        var i;
        var found = false;
        for (i in this.state.team.employees){
            if (this.state.team.employees[i].id === employeeId){
                this.state.team.employees.splice(i);
                found = true;
                break;
            }
        }
        i = 0;
        if (!found){
            for (i in this.state.employees){
                if (this.state.employees[i].id === employeeId){
                    this.state.team.employees.push(this.state.employees[i]);
                    found = true;
                    break;
                }
            }
        }

        this.setState(prevState => ({
            team: {
                ...prevState.team,
            }
        }));
    }

    logoInput() {
        document.getElementById('logoInput').click();
    }

    onLogoUpload(e) {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const reader = new FileReader();
        let formData = new FormData();
        formData.append("image", files[0], "teamLogo.png");
        reader.onload = () => {
            this.setState({logo: {name: files[0].name, src: reader.result}});
            this.setState(prevState => ({
                team: {
                    ...prevState.team,
                    teamLogo: formData,
                }
            }));
        };
        reader.readAsDataURL(files[0]);
    }



    getTeam(teamId) {
        axios.get('/api/team/' + teamId).then(res => {
            const team = res.data;
            this.setState(prevState => ({
                team: team
            }));
        });
    }

    handleSave() {
        const me = this;
        let team = this.state.team;
        let logoImage;
        if (team.teamLogo) {
            logoImage = team.teamLogo;
            team.teamLogo = undefined;
        }
        axios.post('/api/team', team).then(function (response) {
            if (logoImage) {
                    me.imageUpload(response.data.id, logoImage);
            } else {
                enqueueSnackbar('Erfolgreich gespeichert', { variant: 'success' });
                window.location = '/admin/teams';
            }
        }).catch(function (error) {
            enqueueSnackbar(error.message, { variant: 'error' });
            console.error(error);
        });
    }

    imageUpload(teamId, image) {
        const me = this;
        axios.postForm('/api/team/'+ teamId + '/image', image).then(function (team) {
            enqueueSnackbar('Erfolgreich gespeichert', { variant: 'success' });
            window.location = '/admin/teams';
        }).catch(function (error) {
            enqueueSnackbar(error, { variant: 'error' });
            console.error(error);
        });
    }

    isEmployeeSelected(employee){
        var e;
        for (e in this.state.team.employees){
            if (this.state.team.employees[e].id === employee.id){
                return true;
            }
        }
        return false;
    }

    render() {
        return <div id="team">
            <Grid container spacing={3}>
                <Grid item xs={6} sm={8}>
                    <h1>Team</h1>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                    <TextField
                        label="Name"
                        placeholder="Team name"
                        value={this.state.team.name ? this.state.team.name : ''}
                        onChange={this.handleTeamChange('name')}
                        fullWidth
                        required
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <FormControl fullWidth required>
                        <InputLabel htmlFor="location">Location</InputLabel>
                        <Select
                            defaultValue=""
                            value={this.state.team.locationLabel}
                            onChange={this.handleTeamChange('locationLabel')}
                            inputProps={{
                                name: 'location',
                                id: 'location',
                            }}>
                            {this.state.locations.map((location) => <MenuItem value={location.value} key={location.value}>{location.label}</MenuItem>)}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12} sm={4}>
                    <input id="logoInput" hidden type="file" onChange={this.onLogoUpload}/>
                    <TextField
                        label="Logo"
                        onClick={this.logoInput.bind(this)}
                        value={this.state.logo.name}
                        fullWidth
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton>
                                        <CloudUpload/>
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        required
                    />
                </Grid>

                <Grid item xs={12} sm={8}>
                <FormControl fullWidth required>
                            <InputLabel htmlFor="employees">Mitglieder</InputLabel>
                            <Select
                                defaultValue=""
                                multiple
                                value={this.state.employees}
                                onChange={this.handleEmployeeSelection}
                                renderValue={selected => this.state.team.employees.map(function(val,index) {
                                    var str = val.firstName + " " + val.lastName;
                                    return str;
                               }).join(', ')}>
                                {this.state.employees?.map((employee) =>
                                    <MenuItem value={employee.id} key={employee.id}>
                                        <Checkbox checked={this.isEmployeeSelected(employee)}/>
                                        {employee.firstName} {employee.lastName}
                                    </MenuItem>
                                )}
                            </Select>
                        </FormControl>
                </Grid>

                <Grid item xs={12} sm={4}>
                    <Button variant="contained" color="primary" onClick={this.handleSave}>Save</Button>
                </Grid>
            </Grid>
        </div>
    }
}

export default withRouter(Team);
