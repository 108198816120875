import React, {Component} from 'react';
import {Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import axios from "axios";
import { enqueueSnackbar } from 'notistack'

class Menu extends Component{
    constructor(props){
        super(props);
        this.state = {
            locationLabel: this.props.locationLabel,
            menu: {},
            menuFields: {},
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.onMenuChange = this.onMenuChange.bind(this);
        this.getValues = this.getValues.bind(this);
    }

    componentDidMount() {
        this.getMenu();
    }

    getMenu(selectedLocationLabel) {
        let locationLabel = selectedLocationLabel ? selectedLocationLabel : this.state.locationLabel;
        axios.get('/api/menu?locationLabel=' + locationLabel).then(res => {
            const menu = res.data;
            this.setState(prevState => ({
                ...prevState,
                menu: menu,
                menuFields: {
                    appetizer: menu.appetizer.join(', '),
                    mainCourse: menu.mainCourse.join(', '),
                    dessert: menu.dessert.join(', ')
                }
            }));
        });
    }

    getValues(v) {
        if (this.state.menu && this.state.menu[v]) {
            return this.state.menu[v].join(', ');
        }
        return undefined;
    }

    onLocationLabelChange = key => event => {
        const value = event.target.value;
        this.setState(prevState => ({
            ...prevState,
            locationLabel: value
        }));
        this.getMenu(value);
    };

    onMenuChange = key => event => {
        const value = event.target.value;
        const valueObject = value.split(',').map(v => v.trim());
        this.setState(prevState => ({
            menu: {
                ...prevState.menu,
                [key]: valueObject
            },
            menuFields: {
                ...prevState.menu,
                [key]: value
            }
        }));
    };

    handleSubmit() {
        const me = this;
        let locationLabel = this.state.locationLabel;
        axios.put('/api/menu?locationLabel=' + locationLabel,
            this.state.menu
        ).then(function (response) {
            enqueueSnackbar('Erfolgreich gespeichert', { variant: 'success' });
        }).catch(function (error) {
            enqueueSnackbar(error.message, { variant: 'error' });
            console.error(error);
        });
    }

    render() {
        if (this.props.roles?.includes('ROLE_KITCHEN')) {
            return(
                <div id="menu">
                    <Grid container spacing={3}>
                        <Grid item xs={6} sm={8}>
                            <h1>Menü</h1>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={4}>
                            <FormControl fullWidth>
                                <InputLabel htmlFor="location">Standort</InputLabel>
                                <Select
                                    defaultValue=""
                                    value={this.state.locationLabel}
                                    onChange={this.onLocationLabelChange()}
                                >
                                    {this.props.locations.map((location) => <MenuItem value={location}
                                                                                      key={location}>{location}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <div className="alert alert-info">Trenne die Speisen mit einem Komma!</div>
                        </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={4}>
                            <TextField
                               label="Vorspeise"
                               value={this.state.menuFields.appetizer ? this.state.menuFields.appetizer : ''}
                               onChange={this.onMenuChange('appetizer')}
                               fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <TextField
                               label="Hauptspeise"
                               value={this.state.menuFields.mainCourse ? this.state.menuFields.mainCourse : ''}
                               onChange={this.onMenuChange('mainCourse')}
                               fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <TextField
                               label="Nachtisch"
                               value={this.state.menuFields.dessert ? this.state.menuFields.dessert : ''}
                               onChange={this.onMenuChange('dessert')}
                               fullWidth
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Button variant="contained" color="primary" onClick={this.handleSubmit}
                                    style={{float: 'right'}}>Speichern
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            );
        } else {
            return <div className="alert alert-danger mt-2">Keine Berechtigung!</div>
        }
    }
}

export default Menu;
