import React, {Component} from 'react';

import {Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField} from '@mui/material';
import {DatePicker} from '@mui/x-date-pickers';
import axios from "axios";

class GraduationNews extends Component {
    constructor(props) {
        super(props);
        this.state = {
            news: this.props.news,
            teams: [],
            employees: [],
            locations: [
                {value: 'SAARBRUECKEN', label: 'Saarbrücken'},
                {value: 'SCHEIDT', label: 'Scheidt'},
                {value: 'LEIPZIG', label: 'Leipzig'}
            ],
            types: [
                {value: 'BACHELOR', label: 'Bachelor'},
                {value: 'MASTER', label: 'Master'},
                {value: 'DOCTOR', label: 'Doktor'},
                {value: 'TRAINING_DEGREE', label: 'Ausbildungsabschluss'}
            ]
        };

        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleNewsChange = this.handleNewsChange.bind(this);
        this.handleEmployee = this.handleEmployee.bind(this);
        this.handleTeam = this.handleTeam.bind(this);
    }

    componentDidMount() {
        this.getInfos();
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if(this.state.news !== nextProps.news) {
            this.setState(prevState => ({
                news: {
                    ...prevState.news,
                    validFrom: nextProps.news.validFrom,
                    validTo: nextProps.news.validTo,
                    locations: nextProps.news.locations,
                    newsType: nextProps.news.newsType,
                }
            }));
        }
    }

    getInfos() {
        axios.get('/api/team').then(res => {
            const teams = res.data;
            this.setState(prevState => ({
                teams: teams
            }));
        });
        axios.get('/api/employee').then(res => {
            const employees = res.data;
            this.setState(prevState => ({
                employees: employees
            }));
        });
    }

    handleDateChange(begin) {
        this.setState(prevState => ({
            news: {
                ...prevState.news,
                graduationDate: begin,
            }
        }));
    }

    handleNewsChange = key => event => {
        const value = event.target.value;
        this.setState(prevState => ({
            news: {
                ...prevState.news,
                [key]: value
            }
        }));
    };
n
    handleEmployee = key => event => {
        const employee = this.state.employees.find(employee => employee.id === event.target.value);
        this.setState(prevState => ({
            news: {
                ...prevState.news,
                employee: employee
            }
        }));
    }

    handleTeam = key => event => {
        const team = this.state.teams.find(team => team.id === event.target.value);
        this.setState(prevState => ({
            news: {
                ...prevState.news,
                team: team
            }
        }));
    }

    handleSubmit(event) {
        let news = this.state.news;
        news.comment = news.employee.firstName + ' ' + news.employee.lastName;
        news.newsType = 'GRADUATION';
        this.props.onSubmit(news);
    }

    checkData() {
        return (!this.state.news.employee || !this.state.news.team || !this.state.news.type || !this.state.news.graduationDate)
    }

    render() {
        return (
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth required>
                        <InputLabel htmlFor="employee">Mitarbeiter</InputLabel>
                        <Select
                            defaultValue=""
                            inputProps={{
                                name: 'employee',
                                id: 'employee',
                            }}
                            value={this.state.news.employee?.id}
                            onChange={this.handleEmployee()}>
                            {this.state.employees.map((employee) => <MenuItem value={employee.id} key={employee.id}>{employee.firstName} {employee.lastName}</MenuItem>)}
                        </Select>
                        </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth required>
                        <InputLabel htmlFor="team">Team</InputLabel>
                        <Select
                            defaultValue=""
                            inputProps={{
                                name: 'team',
                                id: 'team',
                            }}
                            value={this.state.news.team?.id}
                            onChange={this.handleTeam()}>
                            {this.state.teams.map((team) => <MenuItem value={team.id} key={team.id}>{team.name}</MenuItem>)}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <DatePicker
                        renderInput={(props) => <TextField {...props} />}
                        label="Abschlussdatum"
                        value={this.state.news.graduationDate ? this.state.news.graduationDate : null}
                        minDate={new Date()}
                        onChange={date => this.handleDateChange(date)}
                        autoOk
                        showTodayButton
                        disableToolbar
                        fullWidth
                        required/>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth required>
                        <InputLabel htmlFor="type">Abschlussart</InputLabel>
                        <Select
                            defaultValue=""
                            value={this.state.news.type}
                            inputProps={{
                                name: 'type',
                                id: 'type',
                            }}
                            onChange={this.handleNewsChange('type')}>
                            {this.state.types.map((type) => <MenuItem value={type.value} key={type.value}>{type.label}</MenuItem>)}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField
                        label="Universität"
                        value={this.state.news.university}
                        onChange={this.handleNewsChange('university')}
                        fullWidth
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField
                        label="Studiengang"
                        value={this.state.news.course}
                        onChange={this.handleNewsChange('course')}
                        fullWidth
                    />
                </Grid>

                <Grid item xs={12} className='text-right'>
                    <Button variant="contained" color="primary" onClick={this.handleSubmit} disabled={this.checkData()}>Speichern</Button>
                </Grid>
            </Grid>
        )
    }
}

export default GraduationNews
